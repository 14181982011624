/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
  FC, useState, useEffect, useContext, useRef,
} from 'react';
import SimulationManagerStoreContext from '@/newcar/ui/common/context/SimulationManagerStoreContext';
import SimulationResultManagerStoreContext from '@/newcar/ui/common/context/SimulationResultManagerStoreContext';
import CARMO_CONST from '@/common/util/const';
import * as styles from '@/newcar/ui/detail/organism/PlanDialog.module.styl';
import { Observer } from 'mobx-react-lite';
import ModalTerm from '@/newcar/ui/detail/organism/modal/ModalTerm';
import ModalGrade from '@/newcar/ui/detail/organism/modal/ModalGrade';
import ModalGradeSP from '@/newcar/ui/detail/organism/modal/ModalGradeSP';
import ModalBodyColor from '@/newcar/ui/detail/organism/modal/ModalBodyColor';
import ModalBodyColorSP from '@/newcar/ui/detail/organism/modal/ModalBodyColorSP';
import ModalOption from '@/newcar/ui/detail/organism/modal/ModalOption';
import ModalOptionSP from '@/newcar/ui/detail/organism/modal/ModalOptionSP';
import ModalMaintenance from '@/newcar/ui/detail/organism/modal/ModalMaintenance';
import ModalMaintenanceSP from '@/newcar/ui/detail/organism/modal/ModalMaintenanceSP';
import ModalResult from '@/newcar/ui/detail/organism/modal/ModalResult';
import ModalLease from '@/newcar/ui/detail/organism/modal/ModalLease';
import { ModeType } from '@/newcar/util/const/dialogMode';
import NEWCAR_CONST from '@/newcar/util/const';

type PlanDialogProps ={
  isShowDialog: boolean;
  timeoutToClose: ()=>void;
  mode: ModeType;
  selectedPlanID: NEWCAR_CONST.SIMULATION_PLAN.ID;
  initialStep: number;
  thumbnailUrl: string;
  hasWantCar: boolean;
};

const PlanDialog:FC<PlanDialogProps> = ({
  isShowDialog,
  timeoutToClose,
  mode,
  selectedPlanID,
  initialStep,
  thumbnailUrl,
  hasWantCar,
}) => {
  const simulationManagerStore = useContext(SimulationManagerStoreContext);
  const simulationResultManagerStore = useContext(SimulationResultManagerStoreContext);

  // 最大ステップ数
  let maxStep = 0;
  switch (mode) {
    case 'sp':
      maxStep = 6;
      break;
    case 'all':
      maxStep = 4;
      break;
    case 'bodyColorAndOption':
      maxStep = 2;
      break;
    default:
      // bodyColor, option, maintenance, leaseTermの場合
      maxStep = 1;
      break;
  }

  const [
    step,
    setStep,
  ] = useState(initialStep);
  const [toTop, setToTop] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // フッターのスワイプをイベントを無視する
    const modalEl = modalRef.current;
    modalEl?.addEventListener('touchmove', (event) => event.preventDefault(), { passive: false });
    return () => {
      modalEl?.removeEventListener('touchmove', (event) => event.preventDefault());
    };
  }, [modalRef]);

  // ダイアログの表示アニメーションのため
  useEffect(() => {
    setTimeout(() => setToTop(true), 300);
  }, [isShowDialog]);

  // ダイアログを閉じるアニメーションのため
  const closeDialog = ():void => {
    setToTop(false);
    timeoutToClose();
  };

  // ステップ遷移時にスクロールトップ
  const scrollTop = () => {
    const scrollarea = document.getElementById('detail_dialog_scroll_top');
    if (scrollarea) {
      scrollarea.scrollTo(0, 0);
    }
  };

  // 次にステップへ行く
  const onClickNext = (current: number, max: number):void => {
    if (current < max) {
      setStep(current + 1);
    } else {
      closeDialog();
    }
    scrollTop();
  };

  // ステップを戻る
  const onClickBack = (current: number):void => {
    if (current > 1) {
      setStep(current - 1);
    } else {
      closeDialog();
    }
    scrollTop();
  };

  // ステップの表示出しわけ
  const showContentNum = (num: number):string => {
    switch (num) {
      case 1:
        return styles.showContent1;
      case 2:
        return styles.showContent2;
      case 3:
        return styles.showContent3;
      case 4:
        return styles.showContent4;
      case 5:
        return styles.showContent5;
      case 6:
        return styles.showContent6;
      default:
        return '';
    }
  };

  // プラン合計金額
  const nowSelectDiscountPrice = (): string => {
    let price;
    if (selectedPlanID === NEWCAR_CONST.SIMULATION_PLAN.ID.ORIGINAL) {
      price = simulationManagerStore.nowSelectPrice;
    } else if (selectedPlanID === NEWCAR_CONST.SIMULATION_PLAN.ID.SIMPLE) {
      price = simulationManagerStore.nowSelectSimplePrice;
    } else if (selectedPlanID === NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND) {
      price = simulationManagerStore.nowSelectRecommendPrice;
    } else if (selectedPlanID === NEWCAR_CONST.SIMULATION_PLAN.ID.RICH) {
      price = simulationManagerStore.nowSelectRichPrice;
    }
    if (price) {
      return CARMO_CONST.COMMA_FORMAT(price);
    }
    return '---,---';
  };

  const nextStepText = () => {
    switch (step) {
      case 1:
        return '次へ（グレード選択）';
      case 2:
        return '次へ（カラー選択）';
      case 3:
        return '次へ（オプション選択）';
      case 4:
        return '次へ（メンテナンスプラン選択）';
      case 5:
        return '結果をみる';
      default:
        return '';
    }
  };

  const isSkip = ():boolean => {
    if (maxStep === 4 && (step === 2 || step === 4)) {
      return true;
    }
    if (maxStep === 2 && step === 1) {
      return true;
    }
    return false;
  };

  const isBack = () => maxStep > 1 && step > 1;

  const buttonText = () => {
    if (isSkip()) {
      return 'スキップ';
    } if (step === maxStep) {
      return 'プランを更新';
    }
    return '次へ';
  };

  const getModalName = () => {
    switch (mode) {
      case 'sp':
        return NEWCAR_CONST.SIMULATION_MODAL.SP[step];
      case 'all':
        return NEWCAR_CONST.SIMULATION_MODAL.ORIGINAL[step];
      case 'bodyColorAndOption':
        return NEWCAR_CONST.SIMULATION_MODAL.OTHER[step];
      default:
        return '';
    }
  };

  const gtmTagModalCloseBtn = () => `gtm-newcar-simulator-${getModalName()}-closeBtn`;

  const gtmTagModalNextBtn = () => `gtm-newcar-simulator-${getModalName()}-nextBtn`;

  // MEMO: 保存処理に変更したが戻す可能性あり
  // const goToEntryForm = () => {
  //   if (simulationManagerStore.nowSelectGrade) {
  //     simulationManagerStore.saveEntryBreakdown(NEWCAR_CONST.SIMULATION_PLAN.ID.ORIGINAL);
  //   } else {
  //     simulationManagerStore.nowCarToUndefined();
  //     simulationManagerStore.saveEntryBreakdown(NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND_SP);
  //   }
  //   navigate(NEWCAR_DEFINE.PATH.FORM_CONTACT_INFO);
  // };

  // シミュレーション結果を保存
  const onClickSave = () => {
    simulationResultManagerStore.addSimulation({
      customerSelection: simulationManagerStore.nowCustomerSelection,
      carDigest: simulationManagerStore.nowCarDigest,
      grade: simulationManagerStore.nowGrade,
      bodyColor: simulationManagerStore.nowSimulationBodyColor,
      totalPrice: simulationManagerStore.nowSelectPrice,
      optionstring: simulationManagerStore.nowSelectOption,
      option: simulationManagerStore.nowSimulationOpstions,
    });
    // パラメータを変更
    if (window && !/s1=/.test(window.location.search)) {
      const paramstring = simulationManagerStore.urlParams;
      if (paramstring) {
        if (window.location.pathname.slice(-1) === '/') {
          window.history.replaceState(null, '', `${window.location.pathname}?${paramstring}&s1=1`);
        } else {
          window.history.replaceState(null, '', `${window.location.pathname}/?${paramstring}&s1=1`);
        }
      }
    }
    setIsSaved(true);
  };

  return (
    <Observer>
      {() => (
        <section className={styles.dialogWrap}>
          <div className={`has-background-white-ter ${styles.dialog} ${toTop ? styles.dialogToTop : styles.dialogClose}`}>
            <div className={`${styles.redBand}`} />

            <div className={`${styles.scrollContent} ${mode === 'leaseTerm' && styles.height100}`} id="detail_dialog_scroll_top">
              {/* ヘッダー */}
              <div className={`is-relative has-text-centered ${styles.stepPadding}`}>
                <div>
                  {(() => {
                    switch (mode) {
                      case 'sp':
                        return step !== maxStep ? (
                          <div className={`columns is-mobile is-padding-right-3 ${styles.stepWrap}`}>
                            <div className={`column ${step === 1 ? styles.nowStep : styles.endStep}`}>
                              <i>1</i>
                              <p>ご利用期間</p>
                            </div>
                            <div className={`column ${step < 2 && styles.yetStep} ${step === 2 && styles.nowStep} ${step > 2 && styles.endStep}`}>
                              <i>2</i>
                              <p>グレード</p>
                            </div>
                            <div className={`column ${step < 3 && styles.yetStep} ${step === 3 && styles.nowStep} ${step > 3 && styles.endStep}`}>
                              <i>3</i>
                              <p>ボディカラー</p>
                            </div>
                            <div className={`column ${step < 4 && styles.yetStep} ${step === 4 && styles.nowStep} ${step > 4 && styles.endStep}`}>
                              <i>4</i>
                              <p>オプション</p>
                            </div>
                            <div className={`column ${step < 5 && styles.yetStep} ${step === 5 && styles.nowStep} ${step > 5 && styles.endStep}`}>
                              <i>5</i>
                              <p>メンテプラン</p>
                            </div>
                          </div>
                        ) : (
                          <h2 className={`has-text-centered has-text-weight-light is-padding-bottom-3 ${styles.headerTitle}`}>シミュレーション結果</h2>
                        );
                      case 'all':
                        return (
                          <div className={`columns is-mobile ${styles.stepWrap}`}>
                            <div className={`column ${step === 1 ? styles.nowStep : styles.endStep}`}>
                              <i>1</i>
                              <p>グレード</p>
                            </div>
                            <div className={`column ${step < 2 && styles.yetStep} ${step === 2 && styles.nowStep} ${step > 2 && styles.endStep}`}>
                              <i>2</i>
                              <p>ボディカラー</p>
                            </div>
                            <div className={`column ${step < 3 && styles.yetStep} ${step === 3 && styles.nowStep} ${step > 3 && styles.endStep}`}>
                              <i>3</i>
                              <p>オプション</p>
                            </div>
                            <div className={`column ${step < 4 && styles.yetStep} ${step === 4 && styles.nowStep} ${step > 4 && styles.endStep}`}>
                              <i>4</i>
                              <p>メンテプラン</p>
                            </div>
                          </div>
                        );
                      case 'bodyColorAndOption':
                        return (
                          <div className={`columns is-mobile ${styles.stepWrap} ${styles.stepWrapSmall}`}>
                            <div className={`column ${step === 1 ? styles.nowStep : styles.endStep}`}>
                              <i>1</i>
                              <p>ボディカラー</p>
                            </div>
                            <div className={`column ${step < 2 && styles.yetStep} ${step === 2 && styles.nowStep} ${step > 2 && styles.endStep}`}>
                              <i>2</i>
                              <p>オプション</p>
                            </div>
                          </div>
                        );
                      case 'leaseTerm':
                        return <h2 className="is-size-3 has-text-weight-bold has-text-primary has-text-centered">賃貸期間を選択してください</h2>;
                      default:
                        return <></>;
                    }
                  })()}
                </div>
                <div className={`${styles.closeBtn}`}>
                  {mode === 'leaseTerm' ? (
                    <button type="button" data-gtm={gtmTagModalCloseBtn()} onClick={():void => closeDialog()}>閉じる</button>
                  ) : (
                    <button type="button" className={styles.closeButton} data-gtm={gtmTagModalCloseBtn()} onClick={():void => closeDialog()} />
                  )}
                </div>
              </div>

              {/* コンテンツ */}
              <div className={styles.contentContainer}>
                <div className={`${styles.contentView} ${showContentNum(step)}`}>
                  {(() => {
                    switch (mode) {
                      case 'sp':
                        return (
                          <>
                            <ModalTerm isShow={step === 1} />
                            <ModalGradeSP isShow={step === 2} />
                            <ModalBodyColorSP isShow={step === 3} />
                            <ModalOptionSP isShow={step === 4} hasWantCar={hasWantCar} />
                            <ModalMaintenanceSP isShow={step === 5} />
                            <ModalResult isShow={step === 6} thumbnailUrl={thumbnailUrl} />
                          </>
                        );
                      case 'all':
                        return (
                          <>
                            <ModalGrade />
                            <ModalBodyColor
                              next={onClickNext}
                              currentStep={step}
                              maxStep={maxStep}
                              selectedPlanID={selectedPlanID}
                            />
                            <ModalOption selectedPlanID={selectedPlanID} hasWantCar={hasWantCar} />
                            <ModalMaintenance
                              next={onClickNext}
                              currentStep={step}
                              maxStep={maxStep}
                            />
                          </>
                        );
                      case 'bodyColorAndOption':
                        return (
                          <>
                            <ModalBodyColor
                              next={onClickNext}
                              currentStep={step}
                              maxStep={maxStep}
                              selectedPlanID={selectedPlanID}
                            />
                            <ModalOption selectedPlanID={selectedPlanID} hasWantCar={hasWantCar} />
                          </>
                        );
                      case 'leaseTerm':
                        return <ModalLease closeDialog={closeDialog} />;
                      default:
                        return <></>;
                    }
                  })()}

                </div>
              </div>
            </div>

            {/* フッター */}
            {(() => {
              switch (mode) {
                case 'sp':
                  return (
                    <div className={`has-background-white ${styles.footer} ${styles.isSp}`} ref={modalRef}>
                      <div className={`columns is-mobile is-padding-top-2 is-padding-bottom-2 ${styles.bottomButtonWrap}`}>
                        <button onClick={():void => onClickBack(step)} type="button" className={`button is-large is-margin-3 ${styles.backButton}`}>戻る</button>
                        {step !== maxStep ? (
                          <button
                            onClick={():void => onClickNext(step, maxStep)}
                            type="button"
                            className={`button is-large is-margin-3 is-fullwidth has-text-white has-text-weight-semibold ${styles.isBlack}`}
                            data-gtm={gtmTagModalNextBtn()}
                          >
                            {nextStepText()}
                          </button>
                        ) : (
                          <button
                            onClick={():void => onClickSave()}
                            type="button"
                            className="button is-large is-margin-3 is-fullwidth"
                            data-gtm={gtmTagModalNextBtn()}
                          >
                            {isSaved ? '保存済み' : '結果を保存する'}
                          </button>
                        )}
                      </div>
                      <div className="columns is-mobile is-vcentered is-border-top-line is-padding-4 is-padding-top-3 is-margin-4 is-margin-top-0">
                        <div className="column is-one-quarters is-hidden-touch" />
                        <div className="column is-size-5 has-text-black-ter has-text-weight-bold">お選びのプラン合計金額</div>
                        <div className="column is-narrow">
                          <span className="is-size-5">月額</span>
                          <strong className={`is-size-3 ${styles.priceColorBlue}`}>{nowSelectDiscountPrice()}</strong>
                          <span className="is-size-6">円</span>
                        </div>
                        <div className="column is-one-quarters is-hidden-touch" />
                      </div>
                    </div>
                  );
                case 'all':
                case 'bodyColorAndOption':
                  return (
                    <div className={`has-background-white ${styles.footer}`}>
                      <div className="columns is-mobile is-vcentered is-border-top-line is-border-bottom-line is-padding-4 is-margin-4 is-margin-top-0">
                        <div className="column is-one-quarters is-hidden-touch" />
                        <div className="column is-size-5 has-text-black-ter has-text-weight-bold">お選びのプラン合計金額</div>
                        <div className="column is-narrow">
                          <span className="is-size-5">月額</span>
                          <strong className="is-size-3">{nowSelectDiscountPrice()}</strong>
                          <span className="is-size-6">円</span>
                        </div>
                        <div className="column is-one-quarters is-hidden-touch" />
                      </div>
                      <div className={`columns is-mobile ${styles.bottomButtonWrap}`}>
                        <button
                          type="button"
                          className="button is-large is-margin-3 is-fullwidth"
                          onClick={() => onClickBack(step)}
                        >
                          {isBack() ? '戻る' : 'キャンセル'}
                        </button>
                        <button
                          type="button"
                          className={`button is-large is-margin-3 is-fullwidth ${isSkip() ? '' : 'is-primary'}`}
                          data-gtm={gtmTagModalNextBtn()}
                          onClick={() => onClickNext(step, maxStep)}
                        >
                          {buttonText()}
                        </button>
                      </div>
                    </div>
                  );
                default:
                  return <></>;
              }
            })()}

          </div>

          <div className={`${toTop || 'is-hidden'} ${styles.scrim}`} onClick={():void => closeDialog()} />
        </section>
      )}
    </Observer>
  );
};

export default PlanDialog;
