/* eslint-disable max-len */
/* eslint-disable no-else-return */
import React, { FC } from 'react';
import {
  MAKER_NAME,
  MAKER_ID,
} from '@/newcar/util/const/maker';
import {
  BODY_NAME,
  BODY_ID,
} from '@/newcar/util/const/bodyType';
import CarDigest from '@/common/core/model/carDigest';
import CARMO_CONST from '@/common/util/const';
import NEWCAR_DEFINE from '@/newcar/util/define';
import { Link } from 'gatsby';
import Card from '@/newcar/ui/detail/molecule/Card';
import CarModelPanelRecommended from '@/newcar/ui/detail/molecule/CarModelPanelRecommended';

// style
import * as styles from '@/newcar/ui/detail/organism/RecommendedCarList.module.styl';

type IsNowSale = {isNowSale: boolean}
type PickDigest = Pick<CarDigest, 'name' | 'makerId' | 'bodyType' | 'isMiniCar' | 'taxedMinPrice11' | 'thumbnailUrl' | 'id__normalized' | 'bodyColors'>;
type RecommendDigests = {recommendDigests: {
    sameBodyTypeDigests: PickDigest[],
    sameBodyTypeSameMakerDigests: PickDigest[],
    sameMakerDigests: PickDigest[],
  }
}
type InformationProps = PickDigest & RecommendDigests & IsNowSale;

const RecommendedCarList:FC<InformationProps> = ({
  makerId,
  bodyType,
  isMiniCar,
  recommendDigests,
}) => (
  <>
    <h2 className={`title is-3 has-text-centered ${styles.recommendedHeading}`}>
      その他のおすすめ車種
    </h2>

    <div className={`container ${BODY_NAME(bodyType as unknown as BODY_ID) === '' && !isMiniCar ? 'is-hidden' : ''}`}>
      <Card>
        <h2 className="title is-3 has-text-centered is-margin-bottom-3">
          {MAKER_NAME(makerId as unknown as MAKER_ID)}
          のその他の
          {
            (() => {
              if (isMiniCar) {
                return (BODY_NAME(CARMO_CONST.BODY_TYPE.ID.MINI_CAR as unknown as BODY_ID));
              } else {
                return (BODY_NAME(bodyType as unknown as BODY_ID));
              }
            })()
          }
        </h2>

        <div className={styles.recommendedListContainer}>
          {
            recommendDigests.sameBodyTypeSameMakerDigests.map((d) => (
              <CarModelPanelRecommended
                name={d.name}
                makerName={CARMO_CONST.MAKER.NAME(d.makerId)}
                link={d.id__normalized.toString()}
                thumbnailUrl={d.thumbnailUrl}
                taxedMinPrice11={d.taxedMinPrice11}
                bodyColors={d.bodyColors}
              />
            ))
          }
          <p className={`${styles.recommendedEmptyText} ${recommendDigests.sameBodyTypeSameMakerDigests.length !== 0 ? 'is-hidden' : ''}`}>
            該当車種はありません
          </p>
        </div>

        <div className={`has-text-centered ${recommendDigests.sameBodyTypeSameMakerDigests.length < 3 ? 'is-hidden' : ''}`}>
          {
            (() => {
              if (isMiniCar) {
                return (
                  <Link
                    to={NEWCAR_DEFINE.PATH.MAKER_BODYTYPE(makerId, CARMO_CONST.BODY_TYPE.ID.MINI_CAR)}
                    className={`button is-large is-outlined has-text-dark ${styles.moreButton}`}
                  >
                    もっと見る
                  </Link>
                );
              } else {
                return (
                  <Link
                    to={NEWCAR_DEFINE.PATH.MAKER_BODYTYPE(makerId, bodyType)}
                    className={`button is-large is-outlined has-text-dark ${styles.moreButton}`}
                  >
                    もっと見る
                  </Link>
                );
              }
            })()
          }
        </div>
      </Card>
    </div>

    <div className={`container ${BODY_NAME(bodyType as unknown as BODY_ID) === '' && !isMiniCar ? 'is-hidden' : ''}`}>
      <Card>
        <h2 className="title is-3 has-text-centered is-margin-bottom-3">
          その他の
          {
            (() => {
              if (isMiniCar) {
                return (BODY_NAME(CARMO_CONST.BODY_TYPE.ID.MINI_CAR as unknown as BODY_ID));
              } else {
                return (BODY_NAME(bodyType as unknown as BODY_ID));
              }
            })()
          }
        </h2>

        <div className={styles.recommendedListContainer}>
          {
            recommendDigests.sameBodyTypeDigests.map((d) => (
              <CarModelPanelRecommended
                name={d.name}
                makerName={CARMO_CONST.MAKER.NAME(d.makerId)}
                link={d.id__normalized.toString()}
                thumbnailUrl={d.thumbnailUrl}
                taxedMinPrice11={d.taxedMinPrice11}
                bodyColors={d.bodyColors}
              />
            ))
          }
          <p
            className={`${styles.recommendedEmptyText} ${recommendDigests.sameBodyTypeDigests.length !== 0 ? 'is-hidden' : ''}`}
          >
            該当車種はありません
          </p>
        </div>

        <div className={`has-text-centered ${recommendDigests.sameBodyTypeDigests.length < 3 ? 'is-hidden' : ''}`}>
          {
            (() => {
              if (isMiniCar) {
                return (
                  <Link
                    to={NEWCAR_DEFINE.PATH.BODY_TYPE_PATH(CARMO_CONST.BODY_TYPE.ID.MINI_CAR)}
                    className={`button is-large is-outlined has-text-dark ${styles.moreButton}`}
                  >
                    もっと見る
                  </Link>
                );
              } else {
                return (
                  <Link
                    to={NEWCAR_DEFINE.PATH.BODY_TYPE_PATH(bodyType)}
                    className={`button is-large is-outlined has-text-dark ${styles.moreButton}`}
                  >
                    もっと見る
                  </Link>
                );
              }
            })()
          }
        </div>
      </Card>
    </div>

    <div className="container">
      <Card>
        <h2 className="title is-3 has-text-centered is-margin-bottom-3">
          {MAKER_NAME(makerId as unknown as MAKER_ID)}
          のその他の車種
        </h2>

        <div className={styles.recommendedListContainer}>
          {
            recommendDigests.sameMakerDigests.map((d) => (
              <CarModelPanelRecommended
                name={d.name}
                makerName={CARMO_CONST.MAKER.NAME(d.makerId)}
                link={d.id__normalized.toString()}
                thumbnailUrl={d.thumbnailUrl}
                taxedMinPrice11={d.taxedMinPrice11}
                bodyColors={d.bodyColors}
              />
            ))
          }
          <p
            className={`${styles.recommendedEmptyText} ${recommendDigests.sameMakerDigests.length !== 0 ? 'is-hidden' : ''}`}
          >
            該当車種はありません
          </p>
        </div>

        <div className={`has-text-centered ${recommendDigests.sameMakerDigests.length < 3 ? 'is-hidden' : ''}`}>
          <Link
            to={NEWCAR_DEFINE.PATH.MAKER_PATH(makerId)}
            className={`button is-large is-outlined has-text-dark ${styles.moreButton}`}
          >
            もっと見る
          </Link>
        </div>
      </Card>
    </div>
  </>
);

export default RecommendedCarList;
