// extracted by mini-css-extract-plugin
export const appealPointContainer = "ChosenPoints-module--appeal-point-container--3jSPR";
export const appealPointTitle = "ChosenPoints-module--appeal-point-title--3Ai8g";
export const appealPointCard = "ChosenPoints-module--appeal-point-card--3VINr";
export const appealPointContent = "ChosenPoints-module--appeal-point-content--KGfcn";
export const appealPointContentItem = "ChosenPoints-module--appeal-point-content-item--324n3";
export const appealPointButtonContainer = "ChosenPoints-module--appeal-point-button-container--2uECi";
export const appealPointButtonItem = "ChosenPoints-module--appeal-point-button-item--2u9Gc";
export const isSecondBottom = "ChosenPoints-module--is-second-bottom--3Ypor";
export const appealPointText = "ChosenPoints-module--appeal-point-text--ckrEk";
export const appealPointHeading = "ChosenPoints-module--appeal-point-heading--1TZGu";
export const appealPointThumbnailContainer = "ChosenPoints-module--appeal-point-thumbnail-container--3QSCD";
export const isFirst = "ChosenPoints-module--is-first--rTFKt";
export const isSecond = "ChosenPoints-module--is-second--3tdoH";
export const isThird = "ChosenPoints-module--is-third--YN5ME";
export const isFourth = "ChosenPoints-module--is-fourth--3p7E4";
export const appealPointNoticeList = "ChosenPoints-module--appeal-point-notice-list--1EKYz";
export const appealPointNoticeListItem = "ChosenPoints-module--appeal-point-notice-list-item--2F3tr";
export const appealPointAsterisk = "ChosenPoints-module--appeal-point-asterisk--3TEMB";
export const appealPointAsteriskSub = "ChosenPoints-module--appeal-point-asterisk-sub--1vAS1";
export const isChosenPoints = "ChosenPoints-module--is-chosen-points--3L3if";