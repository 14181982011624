// extracted by mini-css-extract-plugin
export const cardPadding = "PlanSP-module--card-padding--2BPoY";
export const bandSizeLarge = "PlanSP-module--band-size-large--YyJ5t";
export const itemWrap = "PlanSP-module--item-wrap--StFQC";
export const itemBgWhiteter = "PlanSP-module--item-bg-whiteter--3lhXJ";
export const itemTitle = "PlanSP-module--item-title--3LQ_M";
export const selectBox = "PlanSP-module--select-box--2FLoZ";
export const selectBoxBig = "PlanSP-module--select-box-big--TLwji";
export const selected = "PlanSP-module--selected--3D8EL";
export const notSelected = "PlanSP-module--not-selected--2U_zk";
export const priceWrap = "PlanSP-module--price-wrap--3BIQ8";
export const priceTrico = "PlanSP-module--price-trico--3gRsJ";
export const isTablet = "PlanSP-module--is-tablet--1IR23";
export const clearButton = "PlanSP-module--clear-button--2lNzv";
export const width4_5rem = "PlanSP-module--width4_5rem--1mVN8";
export const width2rem = "PlanSP-module--width2rem--2OszQ";
export const width6_5rem = "PlanSP-module--width6_5rem--3F8pW";
export const leaseTerm = "PlanSP-module--lease-term--ESoK5";
export const leaseSelectable = "PlanSP-module--lease-selectable--3h935";
export const balloonCampaign = "PlanSP-module--balloon-campaign--10cqO";
export const balloonCampaignItem = "PlanSP-module--balloon-campaign-item--1FfvQ";
export const saveButton = "PlanSP-module--save-button--3SpuQ";
export const saveButtonText = "PlanSP-module--save-button-text--1kMWT";
export const blueButton = "PlanSP-module--blue-button--32fSx";
export const isDisabled = "PlanSP-module--is-disabled--1xcZk";
export const iconArrowRightGrey = "PlanSP-module--icon-arrow-right-grey--mr4PJ";
export const iconArrowRightBig = "PlanSP-module--icon-arrow-right-big--1YX42";
export const popupPosition = "PlanSP-module--popup-position--2wlWo";
export const popupContent = "PlanSP-module--popup-content--p-z0w";
export const popupContentHalf = "PlanSP-module--popup-content-half---xwa-";
export const simulator_two_colorchip = "PlanSP-module--simulator_two_colorchip--2SYOF";
export const simulator_above_colorchip = "PlanSP-module--simulator_above_colorchip--3Gciv";
export const leaseHelp = "PlanSP-module--lease-help--1WFeA";
export const dokidoki = "PlanSP-module--dokidoki--3P_xx";
export const naviPlanBalloon = "PlanSP-module--navi-plan-balloon--20e8N";
export const naviPlanText = "PlanSP-module--navi-plan-text--1H33F";
export const naviPlanTextMin = "PlanSP-module--navi-plan-text-min--3e8X2";
export const priceColorBlue = "PlanSP-module--price-color-blue--3-Zxd";
export const textSimulationSelected = "PlanSP-module--text-simulation-selected--3Cs92";
export const textMonthlyAfter = "PlanSP-module--text-monthly-after--2Yn-r";
export const textUpRight = "PlanSP-module--text-up-right--6XOIG";
export const simulationChangeButton = "PlanSP-module--simulation-change-button--3WK5q";