// extracted by mini-css-extract-plugin
export const bandSizeLarge = "index-module--band-size-large--2R2cf";
export const hasTextDecoration = "index-module--has-text-decoration--3fDN3";
export const hasBorderTopGreyLighter = "index-module--has-border-top-grey-lighter--3e_z-";
export const moreButton = "index-module--more-button--1sdhr";
export const hasBackgroundBlackLight = "index-module--has-background-black-light--OyT8G";
export const recommendedPoints = "index-module--recommended-points--1r_TR";
export const userVoiceContainer = "index-module--user-voice-container--3sJ5y";
export const userVoiceItem = "index-module--user-voice-item--2z-ab";
export const userVoiceRating = "index-module--user-voice-rating--2sI28";
export const userVoiceRatingImages = "index-module--user-voice-rating-images--rb9OA";
export const scrollToTopDetail = "index-module--scroll-to-top-detail--1OwZ-";