/* eslint-disable max-len */
import React, { FC } from 'react';

// Component
import Card from '@/newcar/ui/detail/molecule/Card';

// Image
import pointFirst from '@/newcar/ui/detail/img/points/img-point-first.png';
import pointSecond from '@/newcar/ui/detail/img/points/img-point-second.png';
import pointThird from '@/newcar/ui/detail/img/points/img-point-third.png';
import pointFourth from '@/newcar/ui/detail/img/points/img-point-fourth.png';

import * as styles from '@/newcar/ui/detail/organism/ChosenPoints.module.styl';

const ChosenPoints:FC = () => (
  <section className="container">
    <Card className={styles.isChosenPoints}>
      <h2 className="title is-3 has-text-centered">
        定額カルモくんが選ばれる
        <br />
        4つのポイント
      </h2>
      <div className={styles.appealPointContainer}>
        <div className={`container is-margin-auto ${styles.appealPointCard}`}>
          <div
            className={`${styles.appealPointContent} ${styles.contentsMarginTop}`}
          >
            {/* point 1 */}
            <div className={styles.appealPointContentItem}>
              <h3 className={styles.appealPointHeading}>
                業界最安水準の
                <br className="is-hidden-mobile" />
                価格
                <span className={styles.appealPointAsteriskSub}>※1</span>
              </h3>
              <div className={`${styles.appealPointThumbnailContainer} ${styles.isFirst}`}>
                <img src={pointFirst} alt="画像" />
              </div>
              <p className={styles.appealPointText}>
                頭金・ボーナス払い０円、車検代・メンテ代・各種税金・自賠責保険料コミで人気の新車が月額1万円台からずっと定額！
              </p>
            </div>
            {/* //_point 1 */}

            {/* point 2 */}
            <div className={styles.appealPointContentItem}>
              <h3 className={styles.appealPointHeading}>
                返却不要＆
                <br className="is-hidden-mobile" />
                抜群の自由度
              </h3>
              <div className={`${styles.appealPointThumbnailContainer} ${styles.isSecond}`}>
                <img src={pointSecond} alt="画像" />
              </div>
              <p className={styles.appealPointText}>
                借り物感ゼロ！
                <span className={styles.appealPointAsteriskSub}>※2</span>
                最終的に車がもらえるからディーラーで購入するのと変わらない使い勝手です。
              </p>
            </div>
            {/* //_point 2 */}

            {/* point 3 */}
            <div className={styles.appealPointContentItem}>
              <h3 className={styles.appealPointHeading}>
                長期保証＆
                <br className="is-hidden-mobile" />
                保険の安心感
              </h3>
              <div className={`${styles.appealPointThumbnailContainer} ${styles.isThird}`}>
                <img src={pointThird} alt="画像" />
              </div>
              <p className={styles.appealPointText}>
                定額カルモくんのメンテナンスプランは、最長11年の安心保証
                <span className={styles.appealPointAsteriskSub}>※4</span>
                が付帯します。さらにリース車両に特化した特約がついたカルモ契約者様専用自動車保険もご用意しております。
              </p>
            </div>
            {/* //_point 3 */}

            {/* point 4 */}
            <div className={styles.appealPointContentItem}>
              <h3 className={styles.appealPointHeading}>
                接客満足度
                <br className="is-hidden-mobile" />
                92.7%
              </h3>
              <div className={`${styles.appealPointThumbnailContainer} ${styles.isFourth}`}>
                <img src={pointFourth} alt="画像" />
              </div>
              <p className={styles.appealPointText}>
                お客様のご要望に寄り添うことを第一に、
                メーカー横断で新車・中古車の中から最適なおクルマをご提案いたします。電話やメール、LINEでもご相談承っておりますのでお気軽にお問合せください。
              </p>
            </div>
            {/* //_point 4 */}
          </div>

        </div>

        <ul className={`${styles.appealPointNoticeList}`}>
          <li className={styles.appealPointNoticeListItem}>
            ※１）文末の制作日における調査結果に基づく。調査概要はコンテンツポリシー参照。
          </li>
          <li className={styles.appealPointNoticeListItem}>
            ※２）7年以上のご契約でもらえるオプション加入時
          </li>
          <li className={styles.appealPointNoticeListItem}>
            ※３）7年以上のご契約
          </li>
          <li className={styles.appealPointNoticeListItem}>
            ※４）プラチナメンテプラン加入時
          </li>
        </ul>
      </div>
    </Card>
  </section>
);

export default ChosenPoints;
